// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDjr7H-LU2wvQlYLKLV5TaTvgEcEYaFTs4",
  authDomain: "gasbillnew2nd.firebaseapp.com",
  databaseURL: "https://gasbillnew2nd-default-rtdb.firebaseio.com",
  projectId: "gasbillnew2nd",
  storageBucket: "gasbillnew2nd.appspot.com",
  messagingSenderId: "888082266957",
  appId: "1:888082266957:web:07751c9683b8f1461a1504",
  measurementId: "G-BTC3SDKPD1"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };